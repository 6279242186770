import React from 'react'
import { Helmet } from 'react-helmet'
import '../../styles/news.less'
import Logo from '../../images/logo.svg'
import bajutina from '../../images/bajutina.jpg'
import grigoriev from './img/grigoriev.svg'
import ScrollToTopOnMount from '../../helpers/scrollRestorationFix'

const TITLE = 'Ипотека.Центр покорила Сибирь'

const NovosibirskMarket = () => {
  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
        <meta name="description" content={TITLE} />
        <meta property="og:description" content={TITLE} />
      </Helmet>
      <ScrollToTopOnMount />
      <div className="news-layout">
        <header className="news-header">
          <a href="/" target="_blank">
            <img src={Logo} alt="logo" />
          </a>
        </header>
        <article className="news-article">
          <h1>{TITLE}</h1>
          <h3 className="news-article__lead">
            Ипотека.Центр усиливает свои позиции на&nbsp;рынке ипотечного
            кредитования. Новый офис компании открылся в&nbsp;Новосибирске.
            Расширение городов присутствия связано с&nbsp;высоким спросом
            на&nbsp;сервис и&nbsp;клиентскую поддержку со&nbsp;стороны
            бизнес-партнеров платформы, специалистов ипотеки
            и&nbsp;недвижимости. Эксперты высоко оценивают потенциал сибирского
            региона и&nbsp;планируют составить серьезную конкуренцию действующим
            игрокам рынка.
          </h3>
          <section className="news-text-section">
            <p className="news-paragraph">
              В&nbsp;Новосибирске начал работать офис Ипотека.Центр. Сибирский
              филиал компании будет оказывать организационную поддержку
              в&nbsp;проведении ипотечных сделок для клиентов пользователей
              онлайн платформы.
            </p>
            <p className="news-paragraph">
              На&nbsp;географию выбора повлиял рост интереса к&nbsp;сервису
              и&nbsp;большой потенциал Сибирского региона. Третий
              по&nbsp;численности город в&nbsp;стране с&nbsp;населением больше
              миллиона жителей, один из&nbsp;передовых российских городов
              по&nbsp;развитию экономики, приросту населения
              и&nbsp;строительству жилой и&nbsp;коммерческой инфраструктуры.
            </p>
            <p className="news-paragraph">
              Для Ипотека.Центр&nbsp;&mdash; это четвертый регион присутствия.
              Офисы компании уже работают в&nbsp;Москве, Санкт-Петербурге
              и&nbsp;Воронеже. Открытие офиса в&nbsp;Сибири еще один шаг для
              компании по&nbsp;усилению позиций на&nbsp;рынке ипотечного
              кредитования.
            </p>
            <p className="news-paragraph">
              Всего за&nbsp;два года существования онлайн-платформы проведено
              более 2&nbsp;300 ипотечных сделок со&nbsp;средней суммой кредита
              в&nbsp;4,5 миллиона рублей. Количество бизнес-партнеров,
              работающих на&nbsp;платформе, уже более 7&nbsp;000&nbsp;человек.
            </p>
          </section>
          <section className="news-citations">
            <div className="news-citations__item">
              <p>
                Для нас выбор Новосибирска, как еще одного города присутствия
                был очевиден. Это третий город в&nbsp;стране, неформальная
                столица Сибири и&nbsp;хороший рынок. Много наших
                бизнес-партнеров из&nbsp;Новосибирска, мы&nbsp;провели
                в&nbsp;Новосибирске уже несколько десятков сделок, и&nbsp;был
                очевидный запрос, чтобы открыть физический офис присутствия.
                Хоть мы&nbsp;и&nbsp;онлайн-платформа, но&nbsp;оффлайн общение
                не&nbsp;заменить, особенно сейчас, когда это стало цениться ещё
                сильнее
              </p>
              <div className="news-citations__author">
                <img src={bajutina} alt="Ольга Бажутина" />
                <div>
                  <h5>Ольга Бажутина</h5>
                  <span>генеральный директор Ипотека.Центр</span>
                </div>
              </div>
            </div>
            <div className="news-citations__item">
              <p>
                Первый раз услышал об&nbsp;Ипотека.Центр примерно летом-осенью
                2018 года и&nbsp;с&nbsp;этого момента начал следить
                за&nbsp;развитием компании. Очень нравится техническая
                реализация платформы, интуитивно понятный интерфейс, качество
                взаимодействия с&nbsp;бизнес-партнерами и&nbsp;клиентами. Ставим
                перед собой в&nbsp;Новосибирске большие планы в&nbsp;2021 году
                составить серьезную, полноценную конкуренцию всем игрокам рынка,
                присутствующим в&nbsp;регионе, а&nbsp;некоторых из&nbsp;них
                обогнать по&nbsp;объемам выдач.
              </p>
              <div className="news-citations__author">
                <img src={grigoriev} alt="Семен Григорьев" />
                <div>
                  <h5>Семен Григорьев</h5>
                  <span>Руководитель Ипотека.Центр в&nbsp;Новосибирске</span>
                </div>
              </div>
            </div>
          </section>
        </article>
        <footer className="news-footer">
          <h6 className="news-footer__title">Справка</h6>
          <p className="news-paragraph">
            Ипотека.Центр&nbsp;&mdash; ипотечная&nbsp;IT компания, находящаяся
            на&nbsp;стыке облачных технологий и&nbsp;реального бизнеса. Компания
            берёт своё начало с&nbsp;августа 2017&nbsp;года. Является ТМ ООО
            &laquo;ФинМаркет&raquo;{' '}
            <a href="/" target="_blank">
              https://ipoteka.center
            </a>
          </p>
          <p className="news-paragraph">
            Платформа Ипотека.Центр&nbsp;&mdash; собственная российская
            разработка ООО &laquo;ФинМаркет&raquo;. Инновационный сервис для
            профессионалов ипотечного бизнеса, позволяет проводить полный цикл
            сделки по&nbsp;ипотечному кредиту онлайн и&nbsp;получать высокое
            агентское вознаграждение. Платформа была запущена в&nbsp;июне
            2019&nbsp;года.
          </p>
          <p className="news-paragraph">
            Контакты для прессы:{' '}
            <a href="mailto:pr@ipoteka.center">pr@ipoteka.center</a>
          </p>
        </footer>
      </div>
    </>
  )
}

export default NovosibirskMarket
